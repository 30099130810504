import React from "react"
import privacyPolicy from "../downloads/Matchmaker_BYU_Privacy_Policy_and_Conditions_of_Use.pdf"

export default function Footer({ className }) {
  return (
    <div
      className={
        "mt-32 md:ml-32 md:absolute md:bottom-0 text-center theme-gray py-2 text-sm " +
        className
      }
    >
      <div className="">
        <span>
          I agree to give my questionnaire answers to Matchmaker BYU and consent
          to them giving my name and email to my match, full policy&nbsp;
          <a className="underline" href={privacyPolicy}>
            here.
          </a>
        </span>
      </div>
    </div>
  )
}
