import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import Slider from "@material-ui/core/slider"

const DEFAULT_VALUE = 4
const MIN_VALUE = 1
const MAX_VALUE = 7
const NUM_INTERVALS = 6
const NUM_AGES = 18
const MIN_AGE = 17
const MAX_AGE = 35
const MIN_HEIGHT = 0
const MAX_HEIGHT = 16

const StyledSlider = withStyles({
  root: {
    color: "#D05476",
    height: 2,
    padding: "15px 0",
  },
  active: {},
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    borderRadius: "0%",
    marginTop: -36,
    marginLeft: -14,
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    borderTop: "20px solid #D05476",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 4,
    backgroundColor: "#D05476",
  },
  rail: {
    height: 4,
    opacity: 0.5,
    backgroundColor: "#717375",
  },
  mark: {
    backgroundColor: "#717375",
    height: 16,
    width: 1,
    marginTop: -7,
  },
  markLabel: {
    fontSize: "10px",
    ["@media (min-width:800px)"]: {
      fontSize: "20px",
    },
    fontFamily: "bilo-extra-light-regular",
  },
})(Slider)

function getAgeMarks() {
  let marks = []
  for (let i = MIN_AGE; i <= MAX_AGE; i++) {
    if (i % 2 === 0) {
      marks.push({ value: i, label: i })
    } else {
      marks.push({ value: i })
    }
  }

  return marks
}

function getHeightMarks() {
  const heights = [
    "5'",
    "5'1\"",
    "5'2\"",
    "5'3\"",
    "5'4\"",
    "5'5\"",
    "5'6\"",
    "5'7\"",
    "5'8\"",
    "5'9\"",
    "5'10\"",
    "5'11\"",
    "6'",
    "6'1\"",
    "6'2\"",
    "6'3\"",
    "6'4\"",
  ]

  let marks = heights.map((height, index) => {
    if (index % 2 == 0) {
      return { value: index, label: heights[index] }
    } else {
      return { value: index }
    }
  })
  return marks
}

function getValueMarks(options) {
  let marks = []
  //Initialize all marks
  for (let i = MIN_VALUE; i <= MAX_VALUE; i++) {
    marks.push({ value: i })
  }

  //Fill relevant marks with labels from question options
  if (options.length == 0) {
    marks[0] = { value: 1, label: "Strongly Disagree" }
    marks[3] = { value: 4, label: "Neutral" }
    marks[6] = { value: 7, label: "Strongly Agree" }
  } else {
    let markSpacing = NUM_INTERVALS / (options.length - 1)
    options.forEach((option, index) => {
      let label = {
        value: 1 + index * markSpacing,
        label: option,
      }

      marks[label.value - 1] = label
    })
  }

  return marks
}

export default function SliderQuestion({
  short_title,
  question,
  curAnswer,
  setCurAnswer,
  options,
}) {
  let is_age_question = question.text === "How old are you?"
  let is_age_preference_question =
    question.text === "What ages would you be willing to date?"
  let is_height_question = question.text === "How tall are you?"
  let is_height_preference_question = question.title === "cond-height"
  let marks
  if (is_age_question || is_age_preference_question) {
    marks = getAgeMarks()
  } else if (is_height_question || is_height_preference_question) {
    marks = getHeightMarks()
  } else {
    marks = getValueMarks(options)
  }
  // let marks = (is_age_question || is_age_preference_question) ? getAgeMarks() : getValueMarks(options)
  let initValue = is_age_question
    ? MIN_AGE
    : is_age_preference_question
    ? [MIN_AGE, MAX_AGE]
    : is_height_question
    ? MIN_HEIGHT
    : is_height_preference_question
    ? [MIN_HEIGHT, MAX_HEIGHT]
    : DEFAULT_VALUE
  let sliderMin =
    is_age_question || is_age_preference_question
      ? MIN_AGE
      : is_height_question || is_height_preference_question
      ? MIN_HEIGHT
      : MIN_VALUE
  let sliderMax =
    is_age_question || is_age_preference_question
      ? MAX_AGE
      : is_height_question || is_height_preference_question
      ? MAX_HEIGHT
      : MAX_VALUE

  const [value, setValue] = useState(curAnswer.answer || initValue)

  useEffect(() => {
    setCurAnswer(initValue)
  }, [])

  return (
    <div className="px-10 md:px-24 py-20 flex flex-col">
      <StyledSlider
        min={sliderMin}
        max={sliderMax}
        step={1}
        value={value}
        onChange={(_, x) => {
          setCurAnswer(x)
          setValue(x)
        }}
        marks={marks}
      />
    </div>
  )
}
