import React, { useState, useEffect } from "react"
import ProgressBar from "../progress-bar"
import Submit from "./submit"
import QuestionCard from "./question_card"
import "./question_styles.css"
import validator from "email-validator"

export default function Survey({
  className = "",
  username,
  isRetaking,
  sessionId,
  questions,
  initialAnswers,
}) {

  const GENDER_Q = 3
  const [responses, setResponses] = useState(questions.map(_ => {}))
  const [questionNumber, setQuestionNumber] = useState(0)
  const [progress, setProgress] = useState(0)

  const allowedThrough = new Set(["kenaiak", "nwoffind", "hnuttal2", "carsenjp", "ehuhem"])

  /*function getCurrentResponseByQuestion(qid) {
    let maybeAnswer = responses.filter(
      r =>
        r !== undefined &&
        r.hasOwnProperty("questionId") &&
        r.questionId === qid
    )
    if (maybeAnswer.length < 1) {
      return "undefined"
    } else {
      return maybeAnswer[0].value
    }
  }*/

  async function incrementQuestion() {
    // if (questionNumber === GENDER_PREF_Q) {
    //   let gender = getCurrentResponseByQuestion(GENDER_Q)
    //   let genderPref = getCurrentResponseByQuestion(GENDER_PREF_Q)
    //   let response = await fetch(
    //     `${process.env.GATSBY_FIREBASE_URI}/shouldAllow`,
    //     {
    //       method: "post",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         gender,
    //         genderPref,
    //       }),
    //     }
    //   )
    //   let responseData = await response.json()
    //   if (responseData.hasOwnProperty("allow") && !responseData.allow) {
    //     alert(
    //       `Sorry! We have reached the survey deadline and currently have an even number of people, so we have closed the survey! We're thinking of doing another round down the road though ...DM us if you think we should!`
    //     )
    //     return
    //   }
    // }

    // if (questionNumber === GENDER_Q && responses[questionNumber].answer === "Female" && !isRetaking && !allowedThrough.has(username)){
    //   alert("Sorry! The survey is currently closed for girls because we have an uneven number of boy and girl responses. Please text a boy to take the survey so we can get back to an even ratio and open it back up for everyone!")
    //   return
    // }

    if (
      process.env.GATSBY_ALLOW_SKIP_QUESTION === "true" ||
      (responses[questionNumber] &&
        (questionNumber == 7 || responses[questionNumber].answer) &&
        responses[questionNumber].answer.length !== 0)
    ) {
      // Do email validation
      if (questionNumber == getQuestionIdFromTitle("email")) {
        if (!validator.validate(responses[questionNumber].answer)) {
          alert("It looks like that's not a valid email address!")
          return
        }
      }

      let nextQuestionId = questionNumber + 1
      while (true) {
        if (
          nextQuestionId < questions.length &&
          questions[nextQuestionId].title.includes("cond") &&
          shouldSkipCondQuestion(questions[nextQuestionId].title)
        ) {
          setAnswer(
            setAnswer({ answer: [], id: nextQuestionId }, nextQuestionId),
            nextQuestionId
          )
          nextQuestionId += 1
        } else {
          break
        }
      }

      setProgress((nextQuestionId / questions.length) * 100)
      setQuestionNumber(nextQuestionId)
    } else {
      alert(
        "Hey there, no skipping questions! Each question helps us find you better matches :)"
      )
    }
  }

  function decrementQuestion() {
    let nextQuestionId = questionNumber - 1

    while (true) {
      if (
        nextQuestionId > 0 &&
        questions[nextQuestionId].title.includes("cond") &&
        shouldSkipCondQuestion(questions[nextQuestionId].title)
      ) {
        setAnswer({ answer: [], id: nextQuestionId }, nextQuestionId)
        nextQuestionId -= 1
      } else {
        break
      }
    }

    if (questionNumber > 0) {
      setQuestionNumber(nextQuestionId)
      setProgress((nextQuestionId / questions.length) * 100)
    }
  }

  function setAnswer(answer, id) {
    setResponses([
      ...responses.slice(0, id),
      { answer, id },
      ...responses.slice(id + 1),
    ])
  }

  function submitResponses() {
    fetch(`${process.env.GATSBY_FIREBASE_URI}/submit`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        sessionId: sessionId,
        answers: responses,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.hasOwnProperty("success") && data.success) {
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}/done?sessionId=${sessionId}`
          )
        } else {
          alert(
            "Hmm, we weren't able to get your answers!  Reach out to us at team@matchmakerprovo.com or try back again in a few minutes!"
          )
        }
      })
  }

  function shouldSkipCondQuestion(title) {
    const qid = getQuestionIdFromTitle("select trait")
    console.log(qid)
    console.log(responses.length)
    console.log(responses[qid])
    return !(responses[qid].answer || []).some(trait =>
      title.includes(trait.toLowerCase())
    )
  }

  function getQuestionIdFromTitle(title) {
    return questions.filter(q => q.title === title)[0].id
  }

  useEffect(() => {
    if (initialAnswers.length == 0) {
      setResponses(questions.map(_ => {}))
    } else {
      setResponses(questions.map(q => initialAnswers[q.id]))
    }
  }, [questions, initialAnswers])

  return (
    <div className={"m-auto max-w-6xl " + className}>
      <ProgressBar
        questionNumber={questionNumber}
        numQuestions={questions.length}
        progress={progress}
      ></ProgressBar>
      {questions.length === 0 ? (
        <p>Authenticating...</p>
      ) : questionNumber < questions.length ? (
        <QuestionCard
          question={questions[questionNumber]}
          questionNumber={questionNumber}
          curAnswer={responses[questionNumber] || {}}
          setCurAnswer={answer => setAnswer(answer, questionNumber)}
          onNext={incrementQuestion}
          onBack={decrementQuestion}
        ></QuestionCard>
      ) : (
        <Submit onSubmit={submitResponses} onBack={decrementQuestion}></Submit>
      )}
    </div>
  )
}
