import React, { useState } from "react"
import MultipleOptionQuestion from "./question_multiple_option"

export default function MultipleChoiceQuestion({
  curAnswer,
  setCurAnswer,
  options,
}) {
  const [value, setValue] = useState(curAnswer.answer || "")

  function multipleChoiceOnChange(e) {
    setCurAnswer(e.target.value)
    setValue(e.target.value)
  }

  function multipleChoiceDetermineChecked(option) {
    return option == value
  }

  return (
    <MultipleOptionQuestion
      onChange={multipleChoiceOnChange}
      determineChecked={multipleChoiceDetermineChecked}
      options={options}
    ></MultipleOptionQuestion>
  )
}
