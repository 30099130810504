import React, { useState } from "react"
import MultipleOptionQuestion from "./question_multiple_option"

export default function MultiSelectQuestion({
  limit,
  curAnswer,
  setCurAnswer,
  options,
}) {
  const [values, setValues] = useState(curAnswer.answer || [])

  function multiSelectOnChange(e) {
    let newValues = values.includes(e.target.value)
      ? values.filter(ans => ans != e.target.value)
      : values.length === limit
      ? [...values]
      : [...values, e.target.value]
    setValues(newValues)
    setCurAnswer(newValues)
  }

  function multiSelectDetermineChecked(option) {
    return values.includes(option)
  }

  return (
    <MultipleOptionQuestion
      onChange={multiSelectOnChange}
      determineChecked={multiSelectDetermineChecked}
      options={options}
    ></MultipleOptionQuestion>
  )
}
