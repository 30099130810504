import React, { useState } from "react"
import shortid from "shortid"
import scrollDown from "../images/valentines-scroll-down.png"
import "./animations.css"

export default function TextPlayer({ className = "", textGroups, onComplete }) {
  const [textGroupIndex, setTextGroupIndex] = useState(0)
  const [textIndex, setTextIndex] = useState(0)
  const [textKeys, _] = useState(
    textGroups.map(group => group.map(() => shortid.generate()))
  )
  const [isFadeOut, setIsFadeOut] = useState(false)
  let isAnimating = false

  function playNext() {
    if (isAnimating) {
      return
    }

    const nextIndex = textIndex + 1
    const isNextGroup = nextIndex % textGroups[textGroupIndex].length == 0
    if (
      nextIndex === textGroups[textGroupIndex].length &&
      textGroupIndex === textGroups.length - 1
    ) {
      if (!isFadeOut) {
        setIsFadeOut(true)
      } else {
        onComplete()
      }
    } else {
      isAnimating = true
      if (isNextGroup && !isFadeOut) {
        setIsFadeOut(true)
        return
      }
      setTextIndex(nextIndex % textGroups[textGroupIndex].length)
      setTextGroupIndex(
        nextIndex === textGroups[textGroupIndex].length
          ? textGroupIndex + 1
          : textGroupIndex
      )
    }
  }

  function endAnimation(i) {
    if (i == textIndex) {
      isAnimating = false
      if (isFadeOut) {
        setIsFadeOut(false)
        playNext()
      }
    }
  }

  return (
    <div className={"flex flex-col justify-center " + className}>
      <div className="mt-10 px-2 md:px-4 md:mt-48 self-center text-center">
        {textGroups[textGroupIndex].map((text, i) => {
          return (
            <div
              key={textKeys[textGroupIndex][i]}
              className={
                isFadeOut
                  ? "animate-fade-out"
                  : i <= textIndex
                  ? "animate-fade-in"
                  : "hidden md:invisible"
              }
              onAnimationEnd={() => endAnimation(i)}
            >
              {text}
            </div>
          )
        })}
      </div>
      <div
        className="sm:ml-32 md:ml-0 absolute bottom-0 self-center text-center mb-6 lg:mb-16"
        style={{
          left: "50%",
          transform: "translateX(-50%",
        }}
      >
        <img
          src={scrollDown}
          className="transition duration-100 ease-in-out transform hover:scale-125 theme-valentines-red cursor-pointer"
          onClick={playNext}
        ></img>
      </div>
    </div>
  )
}
