import React from "react"

export default function ProgressBar({ progress }) {
  return (
    <div className="py-6">
      <div className="h-3 relative max-w-6xl rounded-full pb-4 overflow-hidden">
        <div className="w-full h-full bg-gray-200 absolute"></div>
        <div
          className="h-full transition-width duration-500 ease-in-out progress-bar absolute"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  )
}
