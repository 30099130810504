import React from "react"
import { MdKeyboardArrowLeft } from "react-icons/Md"

export default function Submit({ onSubmit, onBack }) {
  return (
    <div className="m-auto text-center">
      <div className="text-copy mb-4">nice job! you're all done!</div>
      <div className="text-copy-md mb-12">
        Click submit and then look out for emails with your matches! Remember, you're getting 4 matches, released across the 2 weeks leading up to what lovey-doveys call the most romantic day of the year. Bonus points if you go on dates with all 4 to find your one true valentine :)
      </div>
      <button
        className="transition duration-500 ease-in-out bg-theme-red hover:bg-blue-700 text-white text-btn py-2 px-4 rounded"
        onClick={onSubmit}
      >
        submit
      </button>
      <div className="px-6 py-4 justify-self-end flex flex-row justify-between ">
        <button onClick={onBack}>
          <MdKeyboardArrowLeft
            className={`transition duration-500 ease-in-out transform hover:scale-125 text-4xl theme-valentines-red`}
          />
        </button>
      </div>
    </div>
  )
}
