import React, { useState, useEffect } from "react"

function TimeDisplay({ number, interval, color }) {
  return (
    <div className="flex items-center flex-col px-4">
      <div className={`${color} text-emphasis-sm`}>{number}</div>
      <div className={`${color} text-light-regular`}>{interval}</div>
    </div>
  )
}

export default function CountDown({ className, countDownDate, color }) {
  //An hour ahead because in Pacific time
  // const LAUNCH_DATE = new Date(2020, 9, 25, 0, 1, 0, 0)

  function getTimeTillLaunch() {
    // let diff = LAUNCH_DATE - new Date()
    let diff = countDownDate - new Date()

    return {
      days: Math.floor(diff / 1000 / 60 / 60 / 24),
      hours: Math.floor((diff / 1000 / 60 / 60) % 24),
      minutes: Math.floor((diff / 1000 / 60) % 60),
      seconds: Math.floor((diff / 1000) % 60),
    }
  }

  const [timeTillLaunch, setTimeTillLaunch] = useState(getTimeTillLaunch())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeTillLaunch(getTimeTillLaunch())
    }, 1000)
  })

  return (
    <div className={"flex justify-center " + className}>
      <TimeDisplay
        number={timeTillLaunch.days}
        interval="days"
        color={color}
      ></TimeDisplay>
      <TimeDisplay
        number={timeTillLaunch.hours}
        interval="hours"
        color={color}
      ></TimeDisplay>
      <TimeDisplay
        number={timeTillLaunch.minutes}
        interval="minutes"
        color={color}
      ></TimeDisplay>
      <TimeDisplay
        number={timeTillLaunch.seconds}
        interval="seconds"
        color={color}
      ></TimeDisplay>
    </div>
  )
}
