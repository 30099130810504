import React, { useState } from "react"

export default function Dropdown({
  label,
  options,
  open,
  onOpen,
  onClose,
  onSelect,
}) {
  const [answer, setAnswer] = useState(label)

  function selectOption(option) {
    if (open) {
      onClose()
      setAnswer(option)
      onSelect(option)
    }
  }

  return (
    <div className="relative inline-block text-left">
      <div>
        <span className="rounded-md shadow-sm">
          <div className="flex">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
              id="options-menu"
              onClick={() => {
                if (open) {
                  onClose()
                } else {
                  onOpen()
                }
              }}
              aria-haspopup="true"
              aria-expanded="true"
            >
              {answer}
              {open ? (
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  style={{ transform: "scaleY(-1)" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
          </div>
        </span>
      </div>
      <div
        className={
          "origin-top-right z-50 transition duration-300 ease-in-out absolute mt-2 w-64 rounded-md shadow-lg " +
          (open ? "opacity-100" : "opacity-0 invisible")
        }
      >
        <div
          className="rounded-md bg-white shadow-xs overflow-scroll"
          style={{ maxHeight: "16rem" }}
        >
          <div className="py-1">
            {options.map((option, i) => (
              <a
                key={i + " " + option}
                href="#"
                onClick={() => selectOption(option)}
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
