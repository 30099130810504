import React, { useState } from "react"

export default function SocialMediaQuestion({
  curAnswer,
  setCurAnswer,
  options,
}) {
  let initialCheckboxStates = {}
  let initialHandleStates = {}
  options.forEach(option => (initialCheckboxStates[option] = false))
  options.forEach(option => (initialHandleStates[option] = ""))
  const [isUsingSocial, setIsUsingSocial] = useState(initialCheckboxStates)
  const [handles, setHandles] = useState(curAnswer.answer || initialHandleStates)

  function updateIsUsingSocial(e) {
    let newIsUsingSocial = JSON.parse(JSON.stringify(isUsingSocial))
    newIsUsingSocial[e.target.value] = !newIsUsingSocial[e.target.value]
    if (!newIsUsingSocial[e.target.value]) {
      // Clear the handle if unchecked
      updateHandle("", e.target.value)
    }
    setIsUsingSocial(newIsUsingSocial)
  }

  function updateHandle(text, social) {
    let newHandles = JSON.parse(JSON.stringify(handles))
    newHandles[social] = text
    setHandles(newHandles)
    setCurAnswer(newHandles)
    console.log(newHandles)
  }

  return (
    <div className="md:px-6 px-2 py-4 flex flex-col">
      {options.map(socialMediaName => (
        <label key={socialMediaName} className="inline-flex items-center mt-3">
          <input
            type="checkbox"
            value={socialMediaName}
            className="h-5 w-5"
            checked={isUsingSocial[socialMediaName]}
            onChange={e => updateIsUsingSocial(e)}
          ></input>
          <span className="ml-2 text-multiple-choice-option">
            {socialMediaName}
          </span>
          <div className="md:px-12 pl-4 py-4">
            {isUsingSocial[socialMediaName] ? (
              <input
                placeholder="Enter handle here"
                className="answer-input-sm"
                id="answer"
                type="text"
                value={handles[socialMediaName]}
                onChange={e => updateHandle(e.target.value, socialMediaName)}
              ></input>
            ) : (
              <div></div>
            )}
          </div>
        </label>
      ))}
    </div>
  )
}
