import React from "react"
import MultipleChoiceQuestion from "./question_multiple_choice"
import MultiSelectQuestion from "./question_multi_select"
import SliderQuestion from "./question_slider"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/Md"
import ShortAnswerQuestion from "./question_short_answer"
import DropdownQuestion from "./question_dropdown"
import SocialMediaQuestion from "./question_social_media"
import SelectTraitQuestion from "./question_select_traits"

export default function QuestionCard({
  question,
  questionNumber,
  curAnswer,
  setCurAnswer,
  onNext,
  onBack,
}) {
  let leftArrowHidden = questionNumber == 0 ? "invisible" : " "
  return (
    <div className="max-h-full md:card-height flex flex-col md:justify-between rounded">
      <div>
        <div className="md:sticky z-40 top-0 bg-white px-12 py-4 flex">
          <pre className="text-lg md:text-2xl question-number">
            {("" + (questionNumber + 1)).padStart(2, "0")}.{" "}
          </pre>
          <div className="text-lg md:text-2xl text-question">{question.text}</div>
        </div>
        {question.type === "dropdown" ? (
          <DropdownQuestion
            key={questionNumber + 1}
            options={question.options}
            questionId={question.id}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></DropdownQuestion>
        ) : question.type === "short_answer" ? (
          <ShortAnswerQuestion
            key={questionNumber + 1}
            title={question.title}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></ShortAnswerQuestion>
        ) : question.type === "social_media" ? (
          <SocialMediaQuestion
            key={questionNumber + 1}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></SocialMediaQuestion>
        ) : question.type === "multi-select" ? (
          <MultiSelectQuestion
            limit={100}
            key={questionNumber + 1}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></MultiSelectQuestion>
        ) : question.type === "multi-select-2" ? (
          <MultiSelectQuestion
            limit={2}
            key={questionNumber + 1}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></MultiSelectQuestion>
        ) : question.type === "multi-select-3" ? (
          <MultiSelectQuestion
            limit={3}
            key={questionNumber + 1}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></MultiSelectQuestion>
        ) : question.type === "multiple_choice" ? (
          <MultipleChoiceQuestion
            key={questionNumber + 1}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></MultipleChoiceQuestion>
        ) : question.type === "select_trait" ? (
          <SelectTraitQuestion
            key={questionNumber + 1}
            options={question.options}
            questionId={question.id}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></SelectTraitQuestion>
        ) : (
          <SliderQuestion
            key={questionNumber + 1}
            question={question}
            short_title={question.short_title}
            options={question.options}
            curAnswer={curAnswer}
            setCurAnswer={setCurAnswer}
          ></SliderQuestion>
        )}
      </div>
      <div className="px-6 py-4 justify-self-end flex flex-row justify-between">
        <button onClick={onBack}>
          <MdKeyboardArrowLeft
            className={`transition duration-500 ease-in-out transform hover:scale-125 text-4xl theme-valentines-red ${leftArrowHidden}`}
          />
        </button>
        <button onClick={onNext}>
          <MdKeyboardArrowRight className="transition duration-500 ease-in-out transform hover:scale-125 text-4xl theme-valentines-red" />
        </button>
      </div>
    </div>
  )
}
