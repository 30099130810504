import React, { useState } from "react"
import Dropdown from "./dropdown"

export default function DropdownQuestion({ options, curAnswer, setCurAnswer }) {
  const [showDepth, setShowDepth] = useState(0)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(options.map(_ => false))
  let answers = options.map(_ => null)

  function onSelect(answer, level) {
    /*
      1. Set the answer for the dropdown at level
      2. Check each condition - set showDepth to deepest possible level while meeting all conditions
      3. Set current answer to the most specific drop down.
    */
    answers[level] = answer
    let newShowDepth =
      answers.findIndex(
        (_, i) => i !== 0 && answers[i - 1] !== options[i].condition
      ) - 1
    newShowDepth = newShowDepth < 0 ? answers.length - 1 : newShowDepth
    if (level < answers.length - 1) {
      setShowDepth(newShowDepth)
    }
    setCurAnswer(answer)
  }

  function openDropdown(level) {
    let newDropdownState = options.map(_ => false)
    newDropdownState[level] = true
    setDropdownIsOpen(newDropdownState)
  }

  function closeDropdown(level) {
    setDropdownIsOpen(options.map(_ => false))
  }

  return (
    <div className="px-12 py-4 flex">
      {options.slice(0, showDepth + 1).map((dropdown, level) => (
        <div className="mr-4">
          <Dropdown
            key={level}
            level={level}
            label={dropdown.label}
            options={dropdown.options.sort()}
            open={dropdownIsOpen[level]}
            onOpen={() => openDropdown(level)}
            onClose={() => closeDropdown(level)}
            onSelect={answer => onSelect(answer, level)}
          ></Dropdown>
        </div>
      ))}
    </div>
  )
}
