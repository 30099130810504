import React from "react"

export default function MultipleOptionQuestion({
  onChange,
  determineChecked,
  options,
}) {
  return (
    <div className="px-6 py-4 flex flex-col">
      {options.map(option => (
        <label key={option} className="inline-flex items-center mt-3">
          <input
            type="checkbox"
            value={option}
            className="h-5 w-5"
            checked={determineChecked(option)}
            onChange={e => onChange(e)}
          ></input>
          <span className="ml-4 text-normal text-lg md:text-xl theme-gray">{option}</span>
        </label>
      ))}
    </div>
  )
}
