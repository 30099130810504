import React, { useEffect, useState } from "react"

export default function ShortAnswerQuestion({ curAnswer, setCurAnswer }) {
  const [value, setValue] = useState(curAnswer.answer || "")
  useEffect(() => {
    setValue(curAnswer.answer || "")
  }, [curAnswer])
  return (
    <div className="px-12 py-4">
      <input
        className="answer-input"
        id="answer"
        type="text"
        value={value}
        onChange={e => {
          setCurAnswer(e.target.value)
          setValue(e.target.value)
        }}
      ></input>
    </div>
  )
}
