import React, { useState } from "react"

export default function SelectTrait({ curAnswer, setCurAnswer, options }) {
  const traits = options

  return (
    <div className="flex flex-col">
      <p className="text-lg md:text-2xl text-medium theme-valentines-red self-center bold">
        Choose up to 5!
      </p>
      <div className="z-0 flex flex-col items-center">
        {traits.map((trait, index) => {
          return (
            <QuestionThumbnail
              key={index}
              trait={trait}
              curAnswer={curAnswer}
              setCurAnswer={setCurAnswer}
            ></QuestionThumbnail>
          )
        })}
      </div>
    </div>

  )
}

function QuestionThumbnail({ trait, curAnswer, setCurAnswer }) {
  const LIMIT = 5
  const [selected, setSelected] = useState(
    curAnswer.answer && curAnswer.answer.includes(trait)
  )

  function onSelect() {
    if (selected) {
      setSelected(false)
      let firstIndexInAnswers = curAnswer.answer.findIndex(f => f == trait)
      if (firstIndexInAnswers != -1) {
        let newFavoriteQuestions = [
          ...curAnswer.answer.slice(0, firstIndexInAnswers),
          ...curAnswer.answer.slice(firstIndexInAnswers + 1),
        ]
        setCurAnswer(newFavoriteQuestions)
      }
    } else {
      if (curAnswer.answer && curAnswer.answer.length >= LIMIT) {
        return
      }
      setSelected(true)
      let prevFavoriteQuestions = curAnswer.answer || []
      let newFavoriteQuestions = [...prevFavoriteQuestions, trait]
      setCurAnswer(newFavoriteQuestions)
    }
  }

  return (
    <div
      className={`transition duration-500 ease-in-out ${
        selected ? "bg-theme-red" : "bg-gray-300"
      } ${
        selected ? "theme-white" : "theme-valentines-red"
      } m-2 w-2/3 h-10 rounded-md flex relative items-center justify-center cursor-pointer`}
      onClick={onSelect}
    >
      {curAnswer.answer && curAnswer.answer.includes(trait) && (
        <div className=" trait-rank-number absolute left-0 ml-4">
          {curAnswer.answer.indexOf(trait) + 1}
        </div>
      )}
      <div className="m-2 text-light-regular text-center">{trait}</div>
    </div>
  )
}
